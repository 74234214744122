import React from 'react';
import './Contact.css';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import ContactForm from '../../Molecules/ContactForm/ContactForm';
import Breadcrumb from '../../Atoms/BreadCrumb/BreadCrumb';

const Contact = () => {
  return (
    <>
      <div className='contact-page-background'>
        <h1>Contact Luxosky</h1>
      </div>
      <Breadcrumb />
      <Container maxWidth='md'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item lg={8} md={8} sm={8}>
              <h2>Thank you for your interest in Luxosky.</h2>
              <h3 className='color-text'>CONTACT US WITH QUESTIONS OR TO LEARN MORE</h3>
              <p>Simply fill out the form and a Luxosky representative will get back with you shortly.</p>
              <ContactForm />
              <div className='section-padding'></div>
            </Grid>
            <Grid item lg={4} md={4} sm={4}>
              <h3>Our Offices</h3>
              <ul>
                <li>Luxosky New York</li>
                <hr />
                <li>Luxosky Los Angeles</li>
                <hr />
                <li>Luxosky Miami</li>
                <hr />
                <li>Luxosky New Jersey</li> <hr />
                <li>Luxosky Chicago</li> <hr />
                <li>Luxosky Paris</li> <hr />
                <li>Luxosky Dubai</li> <hr />
              </ul>
            </Grid>{' '}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Contact;
