import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import './category.css';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';
import ServiceCard from '../../Atoms/ServiceCard/ServiceCard';

const Category = () => {
  const { id, subId } = useParams();
  const [category, setCategory] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchCategoryData();
  }, [id, subId]);

  const fetchCategoryData = async () => {
    try {
      const response = await fetch('https://chartedjetone.onrender.com/category');
      const categories = await response.json();
      const selectedCategory = categories.find((item) => item._id === id);

      if (selectedCategory) {
        setCategory(selectedCategory);
        if (subId) {
          const subcategory = selectedCategory.categoryContents.find((sub) => sub._id === subId);
          setData(subcategory ? [subcategory] : []);
        } else {
          setData(selectedCategory.categoryContents);
        }
      }
    } catch (err) {
      console.error('Error fetching category data:', err);
    }
  };

  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <div>
      {category && (
        <>
          <div
            className='subcategory-header-img'
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.37), rgba(8, 8, 8, 0.45)), url(${category.image})`,
            }}
          >
            <h1>{category.title}</h1>
            {/* <p dangerouslySetInnerHTML={renderHTML(category.description)} /> */}
          </div>
          {data.map((content, index) => (
            <div key={content._id}>
              <Container maxWidth='lg'>
                <Grid item xs={12} key={index}>
                  <Grid container spacing={2}>
                    {index % 2 === 0 ? (
                      <>
                        <Grid item lg={6} md={6} sm={6}>
                          <img
                            id='cv-card-image'
                            src={content.image}
                            className='subcategory-img'
                            alt={content.title || 'No image description'}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} container alignItems='center'>
                          <div className='subcategory-content'>
                            <h1 id='cv-card-title' className='category-main-title'>
                              {content.title}
                            </h1>
                            <p id='cv-card-title' dangerouslySetInnerHTML={renderHTML(content.description)} />
                          </div>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item lg={6} md={6} sm={6} container alignItems='center'>
                          <div className='subcategory-content'>
                            <h1 id='cv-card-title' className='category-main-title'>
                              {content.title}
                            </h1>
                            <p id='cv-card-title' dangerouslySetInnerHTML={renderHTML(content.description)} />
                            <ButtonPage label='Request a Private Luxosky Quote' />
                          </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                          <img
                            id='cv-card-image'
                            src={content.image}
                            className='subcategory-img'
                            alt={content.title || 'No image description'}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Category;
