import React, { useEffect, useState } from 'react';
import './BlogPage.css';
import BlogCard from '../../Atoms/BlogCard/BlogCard';
import Grid from '@mui/material/Grid';
import { Container, useMediaQuery } from '@mui/material';
import { BASE_URL } from '../../../ApiServices/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';

const BlogPage = () => {
  const [blogData, setBlogData] = useState([]);

  const isSmallScreen = useMediaQuery('(max-width:425px)');

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/blogs`);
      setBlogData(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  const displayedBlog = isSmallScreen ? blogData.slice(0, 2) : blogData.slice(0, 3);
  // const firstBlogId = blogData.length > 0 ? blogData[0]._id : '';

  return (
    <>
      <div className='blogpage-container'>
        <Container maxWidth='lg'>
          <Link to={`/blog-detail`}>
            <p className='view-all-blog'>View All</p>
          </Link>
          <h2 className='blog-area-heading'>Luxosky News & Blog</h2>
          <div className='blog-all-cards'>
            <Grid container spacing={2}>
              {displayedBlog.map((data, index) => (
                <Grid item lg={4} md={4} sm={6} xs={6} key={index}>
                  <BlogCard
                    image={data.imageUrl}
                    date={new Date(data.date).toLocaleDateString()}
                    title={data.title}
                    id={data._id}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BlogPage;
