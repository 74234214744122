import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import './category.css';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';

const SubCategory = () => {
  const { id, subid } = useParams();
  const [data, setData] = useState([]);
  const [subcategory, setSubcategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await fetch('https://chartedjetone.onrender.com/category');
        const data = await response.json();

        const category = data.find((item) => item._id === id);
        if (category) {
          const subcategory = category.subcategories.find((sub) => sub._id === subid);
          if (subcategory) {
            console.log('subcategory', subcategory.contents);
            setData(subcategory.contents);
            setSubcategory(subcategory);
          }
          setData(subcategory.contents);
        } else {
          console.log('Category not found');
        }
      } catch (err) {
        console.log('err', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryData();
  }, [id, subid]);

  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <>
      {loading ? (
        <Grid container justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {subcategory && (
            <div
              className='subcategory-header-img'
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.37), rgba(8, 8, 8, 0.45)), url(${subcategory.image})`,
              }}
            >
              <h1>{subcategory.name}</h1>
            </div>
          )}

          <Grid container spacing={2}>
            {data.length > 0 ? (
              data.map((element, index) => (
                <Container maxWidth='lg'>
                  <Grid item xs={12} key={index}>
                    <Grid container spacing={2}>
                      {index % 2 === 0 ? (
                        <>
                          <Grid item lg={6} md={6} sm={6}>
                            <img
                              data-aos='zoom-in'
                              data-aos-duration='1500'
                              id='cv-card-image'
                              src={element.image}
                              className='subcategory-img'
                              alt={element.title || 'No image description'}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} container alignItems='center'>
                            <div className='subcategory-content'>
                              <h1 id='cv-card-title' className='category-main-title'>
                                {element.title}
                              </h1>
                              {/* <p>{element.body}</p> */}
                              <p id='cv-card-title' dangerouslySetInnerHTML={renderHTML(element.body)} />
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item lg={6} md={6} sm={6} container alignItems='center'>
                            <div className='subcategory-content'>
                              <h1 id='cv-card-title' className='category-main-title'>
                                {element.title}
                              </h1>
                              <p id='cv-card-title' dangerouslySetInnerHTML={renderHTML(element.body)} />
                              <ButtonPage label='Request a Quote' fullWidth />
                            </div>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6}>
                            <img
                              data-aos='zoom-in'
                              data-aos-duration='1500'
                              id='cv-card-image'
                              src={element.image}
                              className='subcategory-img'
                              alt={element.title || 'No image description'}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Container>
              ))
            ) : (
              <Grid item lg={12} md={12} style={{ textAlign: 'center' }}>
                Not found
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default SubCategory;
