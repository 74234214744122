import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AboutCard from '../../Molecules/AboutCard/AboutCard';
import './About.css';
import Breadcrumb from '../../Atoms/BreadCrumb/BreadCrumb';

const About = () => {
  return (
    <div>
      <div className='about-page-background'>
        <h1>About Luxosky</h1>
      </div>{' '}
      <Breadcrumb />
      <Container maxWidth='lg'>
        {' '}
        <Box sx={{ flexGrow: 1, marginTop: '40px' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <h1>At Luxosky, Ready. Set. Jet. is more than a slogan, its how we do business.</h1>
              <h3 className='color-text'>Luxosky IS READY WHEN YOU ARE</h3>
              <strong>Americas | Europe | Asia | Africa | Oceania | United States</strong>
              <p>
                With access to hundreds of airports, a private aircraft can get you closer and faster than any
                commercial airline and in a shorter time. Executives have come to realize that their time is even more
                valuable than ever and waiting over an hour to catch a plane and then commute for another hour to reach
                their final destination is something of the past. By chartering an aircraft executives can take full
                advantage of every hour and at the end become more productive. Professionalism and luxury treatment is
                what you will receive when you choose to travel with us. Our team will provide you with seamless travel.
                When you travel private jet charter with Luxosky you can skip the hassles and headaches of commercial
                airline travel such as parking, layovers, lengthy check ins, security lines, and lost luggage. You will
                be able to travel luxuriously in the private jet of your choice. You can dine in elegance, conduct
                business, or simply relax if you choose.
              </p>
              <h3 className='color-text'>WHY PRIVATE AVIATION?</h3>
              <p>
                Flying commercially provides you with just two choices, coach or first class. Luxosky clients have an
                entire fleet of luxury private jets at their disposal. We recognize that travel needs are as specialized
                and unique as our clients-that’s why we have access to over 5,000 private aircraft available worldwide
                including: fuel efficient turbo props, sleek light jets, versatile medium and super medium jets,
                luxurious heavy jets and even helicopters. The professional pilots we use are the best and the brightest
                of private aviation. Every pilot exceeds FAA requirements and has a minimum of 5,000 hours of flight
                experience on a specific type of private aircraft.
              </p>
              <AboutCard />
              <hr />
            </Grid>
            <Grid item xs={4}>
              <h3>About US</h3>
              <p>Flight Luxosky Company</p>
              <hr />
              <p>Private Jet Safety</p>
              <hr />
              <p>Luxosky Offices</p>
              <hr />
            </Grid>{' '}
          </Grid>{' '}
        </Box>
      </Container>
    </div>
  );
};

export default About;
