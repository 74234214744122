import React from 'react';
import './Quotation.css';
import QuoteForm from '../../Molecules/QuoteForm/QuoteForm';


const Quotation = () => {
  return (
    <div>
      <div className='quote-page-background'>
        <h1>Book a Private Luxosky</h1>
      </div>

      <div className='section-padding'>
        <QuoteForm />
      </div>
      <div className='section-padding'> </div>
    </div>
  );
};

export default Quotation;
