import React, { useEffect, useState } from 'react';
import './RentJet.css';
import Grid from '@mui/material/Grid';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';
import Container from '@mui/material/Container';
import './RentJet.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../ApiServices/Config';

const RentJet = () => {
  const [rentData, setRentData] = useState({});

  const fetchRentData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/homehero`);
      setRentData(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchRentData();
  }, []);

  return (
    <>
      <Container maxWidth='lg'>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item lg={6} md={6} sm={6}>
            <div data-aos='fade-right'>
              <img src={rentData.image} alt='jet' className='rent-jet-img' />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6}>
            <h1 className='rent-jet-head'>{rentData.title}</h1>
            <p className='rent-jet-para'>{rentData.description}</p>
            <Link component={Link} to='/book-jet'>
              {' '}
              <ButtonPage label='Request a Private Luxosky Quote' />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RentJet;
