import React, { useEffect, useState } from 'react';
import './BlogDetail.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import axios from 'axios';
import { BASE_URL } from '../../../ApiServices/Config';

const BlogDetail = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blogs`);
        setBlogs(response.data);

        console.log('response123', response);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <p>Loading...</p>;
  // if (blogs.length === 0) return <p>No blogs found</p>;

  return (
    <div>
      <Container maxWidth='lg'>
        <Box sx={{ flexGrow: 1, marginTop: '40px', padding: '20px' }}>
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} sm={8}>
              {blogs.map((blog) => (
                <div key={blog._id}>
                  <h1>{blog.title}</h1>
                  <p>
                    {new Date(blog.date).toLocaleDateString()} | {blog.author}
                  </p>
                  <img src={blog.imageUrl} className='blog-detail-img' alt='blog' />
                  <p>{blog.content}</p>
                </div>
              ))}
            </Grid>
            <Grid item lg={4} md={4} sm={4}>
              <div className='blog-side-bar'>
                <h3>Recent Blogs</h3>
                {blogs.map((blog) => (
                  <div key={blog._id} className='recent-blog-item'>
                    <h4>{blog.title}</h4>
                    <p>{blog.summary}</p>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>{' '}
        </Box>
      </Container>
    </div>
  );
};

export default BlogDetail;
