import React from 'react';
import './QuoteCard.css';
import ButtonPage from '../ButtonPage/ButtonPage';
import { Link } from 'react-router-dom';

const quoteCard = () => {
  return (
    <div>
      <div className='quote-card-container'>
        <h2>Jet Anywhere in the World with Luxosky</h2>
        <Link to='/book-jet'>
          {' '}
          <ButtonPage label='Request A Quote' />
        </Link>
      </div>
      <div className='orange-line'></div>
    </div>
  );
};

export default quoteCard;
