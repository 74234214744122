import React from 'react';
import './ContactForm.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Captcha from '../../Atoms/Captcha/Captcha';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';

const ContactForm = () => {
  return (
    <>
      <div className='contact-form-container'>
        <Box
          component='form'
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete='off'
        >
          <TextField id='outlined-basic' label='First Name' variant='outlined' />
          <TextField id='outlined-basic' label='Last Name' variant='outlined' />
          <TextField id='outlined-basic' label='Email Address ' variant='outlined' />
          <TextField id='outlined-basic' label='Phone Number ' variant='outlined' />
          <TextField multiline id='outlined-basic' label='aaaa ' variant='outlined' />
          <Captcha />
          <ButtonPage label='Submit' fullWidth />
        </Box>
      </div>
    </>
  );
};

export default ContactForm;
