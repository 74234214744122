import React, { useEffect, useState } from 'react';
import './SelectJet.css';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../ApiServices/Config';

const SelectJet = () => {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const fetchbannerData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/homebanner`);
        setBannerData(response.data[0]);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchbannerData();
  }, []);

  if (!bannerData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div
        className='select-jet-container'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.37), rgba(8, 8, 8, 0.45)), url(${bannerData.image})`,
        }}
      >
        <h4>{bannerData.subtitle}</h4>
        <h1>{bannerData.title}</h1>
        <div className='select-jet-buttons'>
          {bannerData.buttons.map((button, index) => (
            <Link key={index} to={button.link}>
              <ButtonPage label={button.title} className='select-jet-btn' />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectJet;
