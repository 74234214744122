import React, { useEffect, useState } from 'react';
import Home from '../design-system/Templates/HomePage/Home';
import About from '../design-system/Templates/AboutPage/About';
import ContactPage from '../design-system/Templates/ContactPage/Contact';
import Quotation from '../design-system/Templates/Quotation/Quotation';
import Nav from '../design-system/Molecules/Navbar/Nav';
import Footer from '../design-system/Molecules/Footer/Footer';
import BlogDetail from '../design-system/Templates/BlogDetail/BlogDetail';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from '../design-system/Atoms/Loader/Loader';
import OurFleet from '../design-system/Templates/OurFleet/OurFleet';
import SubCategory from '../design-system/Templates/SubCategory/SubCategory';
import ServiceDetailPage from '../design-system/Templates/ServiceDetailPage/ServiceDetailPage';
import Category from '../design-system/Templates/SubCategory/category';
import { Route, Routes, useLocation } from 'react-router-dom';

const AppRoutes = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  const isHomePage = location.pathname === '/';

  return (
    <>
      <Nav />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact-us' element={<ContactPage />} />
        <Route path='/book-jet' element={<Quotation />} />
        <Route path='/blog-detail' element={<BlogDetail />} />
        <Route path='/our-fleet' element={<OurFleet />} />
        <Route path='/category/:id/subcategory/:subid' element={<SubCategory />} />
        <Route path='/category/:id' element={<Category />} />
        <Route path='/service/:id' element={<ServiceDetailPage />} />
      </Routes>
      {!isHomePage && <div className='section-padding'></div>}
      <Footer />
    </>
  );
};

export default AppRoutes;
