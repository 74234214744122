import React from 'react';
import Grid from '@mui/material/Grid';
import about01 from '../../../assets/Images/about01.jpg';
import about02 from '../../../assets/Images/about02.jpg';

const AboutCard = () => {
  const aboutData = [
    {
      image: about01,
      title: 'Price Pledge to our Private Luxosky Clients',
      listItem: {
        itemOne: 'No additional fuel surcharges',
        itemTwo: 'No billed taxi time',
        itemThree: 'No surprise landing fees or ramp fees',
      },
    },
    {
      image: about02,
      title: 'Price Pledge to our Private Luxosky Clients',
      listItem: {
        itemOne: 'No additional fuel surcharges',
        itemTwo: 'No billed taxi time',
        itemThree: 'No surprise landing fees or ramp fees',
      },
    },
  ];

  return (
    <div>
      {aboutData.map((data, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item lg={5} md={6}>
            <img src={data.image} alt='about' />
          </Grid>
          <Grid item lg={7} md={6}>
            <h3>{data.title}</h3>
            <ul>
              <li>{data.listItem.itemOne}</li>
              <li>{data.listItem.itemTwo}</li>
              <li>{data.listItem.itemThree}</li>
              <li>{data.listItem.itemThree}</li>
            </ul>
          </Grid>
        </Grid>
      ))}
     
    </div>
  );
};

export default AboutCard;
