import React, { useEffect, useState } from 'react';
import './ContactLogo.css';
import { Container, Grid } from '@mui/material';
import axios from 'axios';
import {BASE_URL} from '../../../ApiServices/Config';

const LogoItem = ({ image, title, description }) => {
  return (
    <div className='contact-logo'>
      <img src={image} alt='logo' data-aos='zoom-out-up' data-aos-duration='1500' />
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};

const ContactLogo = () => {
  const [logoData, setLogoData] = useState([]);

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/contactinfo`);
        const data = response.data.map((item) => ({
          image: item.image,
          title: item.name,
          description: item.number,
        }));
        setLogoData(data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchLogoData();
  }, []);

  return (
    <>
      <div className='logo-container'>
        <Container maxWidth='lg'>
          <h2 className='contact-logo-heading'>Prefer to speak with us direct? Contact us using a method below:</h2>

          <Grid container spacing={2}>
            {logoData.map((data, index) => (
              <Grid item lg={3} md={3} sm={6} xs={6} key={index}>
                <LogoItem image={data.image} title={data.title} description={data.description} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default ContactLogo;
