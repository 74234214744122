import React, { useEffect, useState } from 'react';
import './Nav.css';
import { AppBar, Toolbar, Button, useMediaQuery, Drawer, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import api from '../../../ApiServices/ApiServices';

const ListNav = ({ isSticky }) => {
  const [navItems, setNavItems] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  // const isSticky = useMediaQuery('(min-width:601px)');

  useEffect(() => {
    fetchNavItems();
  }, []);

  const fetchNavItems = async () => {
    try {
      const response = await api.get('/category');
      setNavItems(response.data);
    } catch (error) {
      console.error('Error fetching navigation items:', error);
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <div className={`navbar-transition ${isSticky ? 'navbar-sticky' : ''}`}>
      <AppBar position='static' color='transparent' sx={{ backgroundColor: 'white' }}>
        <Toolbar>
          {isSmallScreen ? (
            <>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='menu'
                onClick={toggleDrawer(true)}
                sx={{ display: { xs: 'block', sm: 'none', color: 'orange' } }}
              >
                <MenuIcon sx={{ fontSize: '25px' }} />
              </IconButton>
              <Drawer
                anchor='left'
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                  '& .MuiDrawer-paper': {
                    width: '100%',
                    boxSizing: 'border-box',
                  },
                }}
              >
                <div
                  role='presentation'
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className='drawer-content'
                >
                  {navItems.map((item) => (
                    <div key={item._id} className='drawer-nav-item'>
                      <Button
                        color='inherit'
                        component={Link}
                        to={`/category/${item._id}`}
                        sx={{
                          fontSize: '14px',
                          fontWeight: '600',
                          textDecoration: 'underline',
                        }}
                      >
                        {item.category}
                        {item.subcategories.length > 0 && <KeyboardArrowDownIcon />}
                      </Button>
                      {item.subcategories.length > 0 && (
                        <div className='drawer-subcategories'>
                          <ul>
                            {item.subcategories.map((sub, index) => (
                              <li
                                key={index}
                                sx={{
                                  fontSize: '14px',
                                }}
                              >
                                <Link to={`/category/${item._id}/subcategory/${sub._id}`}>{sub.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                  <Divider />
                  <Button
                    component={Link}
                    to='/book-jet'
                    sx={{ color: 'white', backgroundColor: 'orange', fontWeight: 600 }}
                  >
                    Book a Luxosky
                  </Button>
                </div>
              </Drawer>
            </>
          ) : (
            <div className='nav-links'>
              {navItems.map((item) => (
                <div key={item._id} className='dropdown-nav'>
                  <Button color='inherit' component={Link} to={`/category/${item._id}`}>
                    {item.category}
                    {item.subcategories.length > 0 && <KeyboardArrowDownIcon />}
                  </Button>
                  {item.subcategories.length > 0 && (
                    <div className='dropdown-nav-list'>
                      <ul>
                        {item.subcategories.map((sub, index) => (
                          <li key={index}>
                            <Link to={`/category/${item._id}/subcategory/${sub._id}`}>{sub.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}

              <Button
                component={Link}
                to='/book-jet'
                sx={{ color: 'white', backgroundColor: 'orange', fontWeight: 600 }}
              >
                Book a Luxosky
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ListNav;
