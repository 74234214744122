import React from 'react';
import './BlogCard.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ButtonPage from '../ButtonPage/ButtonPage';
import { Link } from 'react-router-dom';

const BlogCard = ({ image, date, title, id }) => {
  return (
    <div>
      <div className='blog-card-inner'>
        <Card sx={{ width: '100%' }}>
          <div className='blog-card-image'>
            <CardMedia sx={{ height: { md: 200, sm: 150, xs: 150 } }} image={image} title='Blog01' />
          </div>
          <CardContent>
            <Typography gutterBottom variant='body2' component='div'>
              {date}
            </Typography>
            <Typography variant='body2'>{title}</Typography>
          </CardContent>
          <CardActions>
            <Link to={`/blog-detail`}>
              <ButtonPage label='Read More' />
            </Link>
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

export default BlogCard;
