import React from 'react';
import './ButtonPage.css';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const ButtonPage = ({ label, onClick, fullWidth = false }) => {
  return (
    <div>
      <Stack spacing={2} direction='row'>
        <Button
          sx={{
            backgroundColor: 'var(--orange-color)',
            textTransform: 'capitalize',
            padding: {
              md: '20px 25px',
              sm: '18px 20px',
              xs: '16px',
            },
            fontSize: {
              md: '16px',
              sm: '14px',
              xs: '12px',
            },
            lineHeight: '3px',
            fontWeight: {
              md: '600',
              sm: '500',
              xs: '500',
            },
          }}
          variant='contained'
          fullWidth={fullWidth}
          onClick={onClick}
        >
          {' '}
          {label}
        </Button>
      </Stack>
    </div>
  );
};

export default ButtonPage;
