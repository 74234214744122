import React, { useEffect, useState } from 'react';
import './Footer.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import directCallLogo from '../../../assets/Images/directCallLogo.png';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';
import footerLogo01 from '../../../assets/Images/footerLogo01.png';
import { Link } from 'react-router-dom';
import api from '../../../ApiServices/ApiServices';

const Footer = () => {
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    fetchNavItems();
  }, []);

  const fetchNavItems = async () => {
    try {
      const response = await api.get('/category');
      setNavItems(response.data);
    } catch (error) {
      console.error('Error fetching navigation items:', error);
    }
  };

  return (
    <>
      <div className='footer-container'>
        <Container maxWidth='lg'>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={5} md={5} sm={6} xs={6}>
                <div className='footer-head'>
                  <img src={directCallLogo} alt='call' />
                  <div>
                    <h4>Call Us Direct</h4>
                    <h2>212-279-1095</h2>
                  </div>
                </div>
                <div className='footer-head'>
                  <img src={directCallLogo} alt='call' />
                  <div>
                    <h4>Call Us Toll Free</h4>
                    <h2>844-251-0555</h2>
                  </div>
                </div>
                <div className='footer-btn'>
                <Link to='/book-jet'>
                  {' '}
                  <ButtonPage label='Request A Quote' />
                </Link>
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <h4>LUXOSKY SERVICES</h4>

                {navItems.map((item) => (
  <ul className='footer-list' key={item._id}>
    <li component={Link} to={`/category/${item._id}`}> {item.category}</li>
    
  </ul>


))}
  <ul>
  <li  component={Link} style={{cursor:'pointer'}}
  to='/book-jet'>Contact Us</li></ul>

               
              </Grid>
      
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <h4>CONNECT</h4>
                <div className='connect-icons'>
                  <FacebookRoundedIcon
                    sx={{
                      fontSize: {
                        md: '45px',
                        sm: '40px',
                      },
                      color: 'darkBlue',
                      padding: '2px',
                    }}
                  />
                  <InstagramIcon
                    sx={{
                      fontSize: {
                        md: '45px',
                        sm: '40px',
                      },
                      color: 'darkOrange',
                      padding: '2px',
                    }}
                  />
                  <LinkedInIcon
                    sx={{
                      fontSize: {
                        md: '45px',
                        sm: '40px',
                      },
                      color: 'blue',
                      padding: '2px',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <div className='footer-end-container'>
          <div className='footer-end-info'>
            <p>© 2024 Luxosky. All Rights Reserved | <strong>Web Design and SEO | Sitemap | Privacy | Cookie Policy </strong></p>
            <p>
              HQ: New York Luxosky, The Graybar Building 420 Lexington Avenue, New York, New York 10170 | View
              All Locations
            </p>
          </div>

          <img src={footerLogo01} alt='logo' />
        </div>
      </div>
    </>
  );
};

export default Footer;
