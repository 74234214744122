import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const BreadCrumb = () => {
  return (
    <>
      <div role='presentation' onClick={handleClick}>
        <Breadcrumbs
          aria-label='breadcrumb'
          sx={{ width: '100%', backgroundColor: 'lightgrey' }}
        >
          <Link
            underline='hover'
            color='inherit'
            href='/'
            sx={{  padding: '4px 0px 4px 50px' }}
          >
            Home
          </Link>
          <Link underline='hover' color='inherit' href='/material-ui/getting-started/installation/'>
            Link
          </Link>
          <Typography color='text.primary'>Page</Typography>
        </Breadcrumbs>
      </div>
    </>
  );
};

export default BreadCrumb;
