import React, { useEffect, useState } from 'react';
import './ServicesTab.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../ApiServices/Config';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ServicesTab = () => {
  const [value, setValue] = useState(0);
  const [tabData, setTabData] = useState([]);

const fetchData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/rentservices`);
    const data = response.data;
    const formattedData = data.map((service) => ({
      label: service.category,
      content: {
        heading: service.title,
        text: service.description,
        image: service.image,
        buttonLabel: 'Experience Our Luxury Luxosky',
      },
    }));
    setTabData(formattedData);
  } catch (error) {
    console.error('Error fetching data', error);
  }
};

  useEffect(() => {
    
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className='tab-container'>
        <h1 className='rent-jet-heading'>Our Luxosky Rental Services</h1>
        <Container maxWidth='lg'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                {tabData.map((tabItem, index) => (
                  <Tab
                    key={index}
                    label={tabItem.label}
                    sx={{
                      textTransform: 'capitalize',
                      backgroundColor: value === index ? '#060e3e' : '#ebebeb',
                      color: value === index ? '#fff' : 'black',
                      margin: '0 1px',
                      fontSize: '14px',
                      '&.Mui-selected': {
                        color: '#fff',
                      },
                    }}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Box>

            {tabData.map((tabItem, index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item lg={6} md={6} sm={6}>
                    <h1 className='rent-jet-tab-head'>{tabItem.content.heading}</h1>
                    <p>{tabItem.content.text}</p>
                    <Link component={Link} to='/book-jet'>
                      <ButtonPage label={tabItem.content.buttonLabel} />
                    </Link>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <div data-aos='fade-left' data-aos-duration='1500'>
                      <img src={tabItem.content.image} alt='jet' className='rent-jet-img' />
                    </div>
                  </Grid>
                </Grid>
              </CustomTabPanel>
            ))}
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default ServicesTab;
