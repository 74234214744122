import React from 'react';
import './Loader.css';
import loadingImage from '../../../assets/Images/loadingImage.gif';

const Loader = () => {
  return (
    <>
      <div className='loader-container'>
        <img src={loadingImage} alt='loading' className='loading-gif' />
      </div>
    </>
  );
};

export default Loader;
