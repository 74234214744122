import React, { useState } from 'react';
import axios from 'axios';
import './QuoteForm.css';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const QuoteForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    country: '',
    phone: '',
    email: '',
    tripType: 'Round Trip',
    departureCity: '',
    departureDate: '',
    departureTime: '',
    arrivalCity: '',
    returnDate: '',
    returnTime: '',
    numberOfPassengers: 1,
    preferredAircraft: '',
    haveFlownPrivateBefore: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://chartedjetone.onrender.com/flightinfo', formData);
      console.log('Success:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth='lg'>
      <div className='form-container'>
        <Box
          component='form'
          sx={{
            background: 'rgba(255, 255, 255, 1.95)',
            padding: {
              md: '20px 40px',
              sm: '20px 30px',
              xs: '20px',
            },
            borderRadius: '8px',
            zIndex: 1000,
            position: 'relative',
          }}
          noValidate
          autoComplete='off'
        >
          <h2>Request a Quote for a Private Jet Rental</h2>
          <p>
            Please fill out the form below and a Luxosky representative will follow up shortly regarding your custom
            private jet Luxosky request.
          </p>
          <div className='form-heading'>
            <div className='form-heading-left'>
              <span>TRAVELER INFORMATION</span>
            </div>
            <div className='form-heading-right'>
              <span>*Requires Answer</span>
            </div>
          </div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='firstName'
                    placeholder='First Name*'
                    value={formData.firstName}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='lastName'
                    placeholder='Last Name*'
                    value={formData.lastName}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='company'
                    placeholder='Company'
                    value={formData.company}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='country'
                    placeholder='Country'
                    value={formData.country}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='phone'
                    placeholder='Phone*'
                    value={formData.phone}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='email'
                    placeholder='Email*'
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
            </Grid>
          </Box>
          <div className='form-heading'>
            <div className='form-heading-left'>
              <span>FLIGHT INFORMATION</span>
            </div>
            <div className='form-heading-right'>
              <span>*Requires Answer</span>
            </div>
          </div>
          <div className='form-radio-buttons'>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='tripType'
                sx={{ backgroundColor: 'lightgrey', marginTop: '10px', padding: '0px 5px' }}
                value={formData.tripType}
                onChange={handleChange}
              >
                <FormControlLabel value='Round Trip' control={<Radio />} label='Round Trip' />
                <FormControlLabel value='One Way' control={<Radio />} label='One Way' />
              </RadioGroup>
            </FormControl>
          </div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='departureCity'
                    placeholder='Departure City*'
                    value={formData.departureCity}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='departureDate'
                    type='date'
                    placeholder='Departure Date*'
                    value={formData.departureDate}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='departureTime'
                    type='time'
                    placeholder='Departure Time*'
                    value={formData.departureTime}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='arrivalCity'
                    placeholder='Arrival City'
                    value={formData.arrivalCity}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    fullWidth
                    variant='outlined'
                  />
                </Item>
              </Grid>
              {formData.tripType === 'Round Trip' && (
                <>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Item>
                      <TextField
                        name='returnDate'
                        type='date'
                        placeholder='Return Date*'
                        value={formData.returnDate}
                        onChange={handleChange}
                        sx={{ '& input::placeholder': { fontSize: '14px' } }}
                        variant='outlined'
                        fullWidth
                        required
                      />
                    </Item>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Item>
                      <TextField
                        name='returnTime'
                        type='time'
                        placeholder='Return Time*'
                        value={formData.returnTime}
                        onChange={handleChange}
                        sx={{ '& input::placeholder': { fontSize: '14px' } }}
                        fullWidth
                        variant='outlined'
                      />
                    </Item>
                  </Grid>
                </>
              )}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <TextField
                    name='numberOfPassengers'
                    type='number'
                    placeholder='Number of Passengers*'
                    value={formData.numberOfPassengers}
                    onChange={handleChange}
                    sx={{ '& input::placeholder': { fontSize: '14px' } }}
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <FormControl fullWidth>
                    <InputLabel id='preferred-aircraft-label' sx={{ fontSize: '14px' }}>
                      Preferred Aircraft
                    </InputLabel>
                    <Select
                      sx={{ lineHeight: '2em' }}
                      labelId='preferred-aircraft-label'
                      id='preferred-aircraft'
                      name='preferredAircraft'
                      value={formData.preferredAircraft}
                      label='Preferred Aircraft'
                      onChange={handleChange}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='Aircraft 1'>Aircraft 1</MenuItem>
                      <MenuItem value='Aircraft 2'>Aircraft 2</MenuItem>
                      <MenuItem value='Aircraft 3'>Aircraft 3</MenuItem>
                    </Select>
                  </FormControl>
                </Item>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Item>
                  <FormControl fullWidth>
                    <InputLabel id='Have-You-Flown' sx={{ fontSize: '14px' }}>
                      Have You Flown Private Before?*
                    </InputLabel>
                    <Select
                      sx={{ lineHeight: '2em' }}
                      labelId='Have-You-Flown'
                      id='Have-You-Flown'
                      name=' HaveYouFlown'
                      value={formData.haveFlownPrivateBefore}
                      label=' Have You Flown Private Before?*'
                      onChange={handleChange}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='yes'>Yes</MenuItem>
                      <MenuItem value='no'>No</MenuItem>
                    </Select>
                  </FormControl>
                </Item>
              </Grid>
            </Grid>
            <FormControlLabel
              control={<Checkbox name='termsconditions' checked={formData.termsconditions} onChange={handleChange} />}
              label='I agree to the terms and conditions'
              componentsProps={{ typography: { align: 'right', fontSize: '12px' } }}
              sx={{ width: '100%', justifyContent: 'flex-end' }}
            />
            <br />
            <FormControlLabel
              control={<Checkbox name='emailoffers' checked={formData.emailoffers} onChange={handleChange} />}
              label='I would like to opt in for occasional email offers'
              componentsProps={{ typography: { align: 'right', fontSize: '12px' } }}
              sx={{ width: '100%', justifyContent: 'flex-end' }}
            />
          </Box>
          <ButtonPage type='submit' onClick={handleSubmit} label='Submit' />
        </Box>
      </div>
    </Container>
  );
};

export default QuoteForm;
