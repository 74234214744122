import React, { useEffect, useState } from 'react';
import './ImageCarousel.css';
import Carousel from 'react-material-ui-carousel';
import ButtonPage from '../../Atoms/ButtonPage/ButtonPage';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../ApiServices/Config';
import SkeletonCarousel from '../Skeleton/SkeletonCarousel';

const ImageCarousel = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/homeslider`);
        setItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setLoading(false);
      }
    };
    fetchItems();
  }, []);

  return (
    <div className='carousel'>
      {loading ? (
        <SkeletonCarousel />
      ) : (
        <Carousel>
          {items.map((item, i) => (
            <CarouselItem key={i} item={item} />
          ))}
        </Carousel>
      )}
    </div>
  );
};

function CarouselItem({ item }) {
  return (
    <>
      <img src={item.image} className='slider-img' alt='slide' />
      <div className='slide-text'>
        <h2>{item.title}</h2>

        <h6>{item.description}</h6>
        <Link component={Link} to='/book-jet'>
          <ButtonPage label='Request a Private Jet Luxosky Quote' />
        </Link>
      </div>
    </>
  );
}

export default ImageCarousel;
