import React, { useEffect, useState } from 'react';
import './Nav.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListNav from './ListNav';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import bookingLogo from '../../../assets/Images/bookingLogo.png';
import { BASE_URL } from '../../../ApiServices/Config';
import axios from 'axios';

const Nav = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  // const [heading, setHeading] = useState('');
  const [navLogo, setNavLogo] = useState('');
  const [navItem, setNavItem] = useState([]);
  const [sideBarItems, setSideBarItems] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const fetchHeading = async () => {
  //     try {
  //       const response = await axios.get(`${BASE_URL}/heading`);
  //       const headingData = response.data[0];
  //       setHeading(headingData.heading);
  //     } catch (error) {
  //       console.error('Error fetching heading:', error);
  //     }
  //   };
  //   fetchHeading();
  // }, []);

  useEffect(() => {
    fetchLogo();
  }, []);
  const fetchLogo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/logo`);
      setNavLogo(response.data[0].image);
    } catch (error) {
      console.error('Error fetching logo', error);
    }
  };

  useEffect(() => {
    fetchContactInfo();
  }, []);

  const fetchContactInfo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/contactinfo`);
      const contacts = response.data.map((contact) => ({
        icon: contact.image,
        label: contact.name,
        number: contact.number,
      }));
      setNavItem(contacts);
      setSideBarItems(contacts);
    } catch (error) {
      console.log('Error fetching data', error);
    }
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static' sx={{ backgroundColor: '#060e3e' }}>
          {/* <div className='nav-info'>
            <h6>{heading}</h6>
          </div> */}

          <Toolbar>
            <div className='navbar-main-logo'>
              <Link to='/'>
                <img src={navLogo} alt='logo' />
              </Link>
            </div>

            <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexGrow: 1, justifyContent: 'space-around' }}>
              {navItem.map((item, index) => (
                <div key={index} className='nav-head'>
                  <img src={item.icon} alt='call' />
                  <div>
                    <h6>{item.label}</h6>
                    <h5>{item.number}</h5>
                  </div>
                </div>
              ))}
            </Box>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={toggleDrawer(true)}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <img src={bookingLogo} alt='book' className='booking-logo' />
            </IconButton>
          </Toolbar>
          <ListNav isSticky={isSticky} />
        </AppBar>
        <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
          <List>
            {sideBarItems.map((item, index) => (
              <ListItem button key={index}>
                <ListItemIcon>
                  <img
                    src={item.icon}
                    alt='icon'
                    style={{ height: 30, width: 40, backgroundColor: '#060e3e', padding: '2px', borderRadius: '50%' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ style: { color: '#060e3e', fontWeight: '700' } }}
                  secondary={item.number}
                  secondaryTypographyProps={{ style: { color: '#faa01b', fontWeight: '600' } }}
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </div>
  );
};

export default Nav;
