import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import './SkeletonCarousel.css';

const SkeletonCarousel = () => {
  return (
    <>
      <div className='skeleton-carousel'>
        <Skeleton variant='rectangular' className='skeleton-img' />
      </div>
    </>
  );
};

export default SkeletonCarousel;
