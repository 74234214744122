import React from 'react';
import './Home.css';
import ImageCarousel from '../../Atoms/ImageCarousel/ImageCarousel';
import QuoteForm from '../../Molecules/QuoteForm/QuoteForm';
import ContactLogo from '../../Molecules/ContactLogos/ContactLogo';
import SelectJet from '../../Molecules/SelectJet/SelectJet';
import RentJet from '../../Molecules/RentJet/RentJet';
import ServicesTab from '../../Molecules/ServicesTab/ServicesTab';
import BlogPage from '../../Molecules/BlogPage/BlogPage';
import QuoteCard from '../../Atoms/QuoteCard/QuoteCard';


const Home = () => {
  

  return (
    <div>
      <ImageCarousel />
      {/* <QuoteForm /> */}
       <ContactLogo/>
      <div className='section-padding'>
        <SelectJet />
      </div>{' '}
      <div className='section-padding'>
        <RentJet />{' '}
      </div>{' '}
      <div className='section-padding'>
        <ServicesTab />
      </div>{' '}
      <div className='section-padding'> </div>
      <BlogPage />
      <QuoteCard />
    </div>
  );
};

export default Home;
